<template>
  <component :is="contentItemData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="contentItemData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching report data
      </h4>
      <div class="alert-body">
        No {{ $props.contentType }} found with this {{ $props.contentTypeSingular }} id. Check
        <b-link
          class="alert-link"
          :to="{ name: $props.contentType}"
        >
          {{ $props.contentName }} List
        </b-link>
        for other reports.
      </div>
    </b-alert>

    <b-tabs
      v-if="contentItemData"
      pills
    >

      <!-- Tab: Overview -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Overview</span>
        </template>
        <content-items-edit-tab-overview
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Content -->
      <b-tab v-if="$props.contentType === 'reports'">
        <template #title>
          <feather-icon
            icon="FileIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">PDF</span>
        </template>
        <reports-edit-tab-content
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab v-if="$props.contentType === 'galleries'">
        <template #title>
          <feather-icon
            icon="ImageIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Photos</span>
        </template>
        <galleries-edit-tab-content
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
          @photoAdded="handleContentUpdate"
        />
      </b-tab>

      <!-- Tab: Content -->
      <b-tab v-if="$props.contentType === 'files'">
        <template #title>
          <feather-icon
            icon="FileIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">File</span>
        </template>
        <content-files-edit-tab-content
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab v-if="$props.contentType === 'playlists'">
        <template #title>
          <feather-icon
            icon="VideoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Videos</span>
        </template>
        <playlists-edit-tab-content
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
          @videoAdded="handleContentUpdate"
        />
      </b-tab>

      <b-tab v-if="$props.contentType === 'events'">
        <template #title>
          <feather-icon
            icon="CalendarIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Event Details</span>
        </template>
        <events-edit-tab-content
          :content-item-data="contentItemData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import ContentItemsEditTabOverview from './ContentItemsEditTabOverview.vue'
import ContentItemsEditTabPermissions from './ContentItemsEditTabPermissions.vue'

import ReportsEditTabContent from '../../report/reports-edit/ReportsEditTabContent.vue'
import ContentFilesEditTabContent from '../../content-file/content-files-edit/ContentFilesEditTabContent.vue'
import GalleriesEditTabContent from '../../gallery/galleries-edit/GalleriesEditTabContent.vue'
import PlaylistsEditTabContent from '../../playlist/playlists-edit/PlaylistsEditTabContent.vue'
import EventsEditTabContent from '../../event/events-edit/EventsEditTabContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ContentItemsEditTabOverview,
    ContentItemsEditTabPermissions,
    ReportsEditTabContent,
    ContentFilesEditTabContent,
    GalleriesEditTabContent,
    PlaylistsEditTabContent,
    EventsEditTabContent,
  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const contentItemData = ref(null)
    const orgOptions = ref(null)

    function getContentItemData() {
      store.dispatch('app/getContentItem', {
        contentType: props.contentType,
        id: router.currentRoute.params.id,
      })
        .then(response => {
          const item = response.data.results[0]
          // collapse organisations object array down to an array of IDs
          item.organisations = item.organisations.map(({ id }) => id)
          // collapse topics object array down to an array of IDs
          item.topics = item.topics.map(({ id }) => id)
          // change event object to event id
          if (item.parent_event != null) item.parent_event = item.parent_event.id
          // set user id
          item.user_id = item.owner.id
          contentItemData.value = item
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 404) {
              contentItemData.value = undefined
            } else {
              console.error(error)
            }
          }
        })
    }

    getContentItemData()

    return {
      getContentItemData,
      contentItemData,
      orgOptions,
    }
  },
  methods: {
    handleContentUpdate() {
      this.getContentItemData()
    },
  },
}
</script>

<style>

</style>
