<template>
  <div>

    <!-- Header: Report -->
    <h4 class="mb-2">
      Attached File
    </h4>
    <h5>Existing file:</h5>
    <p v-if="$props.contentItemData.content.url">
      <a @click="getFile" href="#"><feather-icon
        icon="FileIcon"
        size="19"
      /> {{ $props.contentItemData.slug }}</a>
      <b-button
        variant="outline-warning"
        size="sm"
        class="mx-2"
        @click="onDelete"
      >
        Delete file
      </b-button>
    </p>
    <p v-if="!$props.contentItemData.content.url">
      None
    </p>

    <!-- Form: Report Form -->
    <b-form
      class="mt-2"
      autocomplete="off"
      @submit.prevent="onSubmit"
    >
      <b-row>

        <!-- Field: PDF File -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Upload a new file:</h5>

          <b-form-group>
            <b-form-file
              id="attachedFile"
              ref="file"
              v-model="attachedFile"
              class="form-control"
              @change="uploadFile"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Action Buttons -->
      <b-button
        v-if="file"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
        :disabled="!file.files.length"
      >
        Upload
      </b-button>
      <b-button
        variant="outline-secondary"
        :to="{ name: 'reports-view', params: { id: $props.contentItemData.id } }"
      >
        Cancel
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormFile,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachedFile: null,
    }
  },
  methods: {
    uploadFile() {
      // eslint-disable-next-line prefer-destructuring
      this.$props.contentItemData.file = this.$refs.file.$refs.input.files[0]
    },
  },
  setup(props) {
    const file = ref(null)
    const toast = useToast()

    const getFile = () => {
      store.dispatch('app/getFileFile', { id: router.currentRoute.params.id })
        .then(response => {
          // open file
          window.open(response.data.results[0].url, '_blank', 'noreferrer');
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating file.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }
    const onSubmit = () => {
      store.dispatch('app/addFileFile', { id: router.currentRoute.params.id, file: props.contentItemData.file })
        .then(response => {
          props.contentItemData.content.url = response.data.results[0].content.url
          file.value.files = []
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
          // log error
            console.error(error)
          } else {
          // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating file.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    const onDelete = () => {
      store.dispatch('app/deleteFileFile', { id: router.currentRoute.params.id })
        .then(() => {
          props.contentItemData.content.url = null
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating file.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    return {
      getFile,
      onSubmit,
      onDelete,
      file,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
