<template>
  <content-items-edit
    content-type="galleries"
    content-type-singular="gallery"
    content-name="Galleries"
    content-name-singular="Gallery"
  />
</template>

<script>
import ContentItemsEdit from '@/views/content-item/content-items-edit/ContentItemsEdit.vue'

export default {
  components: {
    ContentItemsEdit,
  },
}
</script>

<style scoped>

</style>
