<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="VideoIcon"
        size="19"
      />
      <h4 class="mb-3 ml-50">
        Videos
      </h4>
    </div>

    <b-overlay
      :show="reloading"
      variant="transparent"
      spinner-variant="primary"
      blur="5px"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col>
          <h5>Current Videos</h5>
          <ul
            ref="sortableList"
            class="videoList"
          >
            <li
              v-for="video in contentItemData.content.videos"
              :id="video.id"
              :key="video.id"
            >
              <img
                width="300"
                height="160"
                :src="`${video.video_url}/thumbnails/thumbnail.jpg?time=1s&width=300&height=160&fit=crop`"
                :alt="`Thumbnail for video ${video.id}`"
              >
              <b-button
                class="videoDelete"
                variant="danger"
                @click="() => deleteVideo(video.id)"
              >
                Delete
              </b-button>
            </li>
          </ul>

          <p v-if="!contentItemData.content.videos.length">
            This playlist currently has no videos
          </p>
        </b-col>
      </b-row>
    </b-overlay>

    <h5 class="mt-5 mb-1">Add more videos</h5>
    <b-row>
      <b-col lg="3">

        <div
          id="drag-drop-area"
          ref="dragdrop"
          style="height: 300px"
        />
      </b-col>
      <b-col lg="3">
        <h5 v-if="uppyFileList.length">
          Upload List
        </h5>
        <p
          v-for="file in uppyFileList"
          :key="file.id"
        >
          <feather-icon
            v-if="file.status ==='complete'"
            class="greenIcon"
            icon="CheckCircleIcon"
            size="26"
          />

          <feather-icon
            v-if="file.status === 'waiting'"
            icon="FileIcon"
            size="26"
          />

          <feather-icon
            v-if="file.status === 'error'"
            class="redIcon"
            icon="XCircleIcon"
            size="26"
          />
          {{ file.name }}
        </p>
        <b-button
          v-if="uppyFileList.length"
          variant="primary"
          block
          :disabled="uploading"
          @click="startUpload"
        >
          {{ uploading ? 'Uploading' : 'Upload' }}
        </b-button>
        <div
          id="progress-bar"
          ref="progressbar"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import DragDrop from '@uppy/drag-drop'
import ProgressBar from '@uppy/progress-bar'
import Sortable from 'sortablejs'
import arrayMove from '@/libs/arrayOrder'
import { $themeConfig } from '@themeConfig'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

export default {
  components: {
    BRow, BCol, BButton, BOverlay,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    uppy: null,
    uppyFileList: [],
    uploading: false,
    existingFiles: [],
    reloading: false,
  }),
  watch: {
    contentItemData() {
      this.reloading = false
    },
  },
  mounted() {
    store.dispatch('app/getVideoUploadToken', { uuid: this.contentItemData.id }).then((res) => {
      const { token, expiry } = res.data;

      this.uppy = new Uppy({
        debug: true,
        autoProceed: false,
        allowMultipleUploads: true,
        restrictions: {
          allowedFileTypes: ['video/*'],
        },
      })

      const { content } = this.contentItemData;
      const { videos } = content;
      // Create a map of the existing files for sorting purposes
      this.existingFiles = videos.map(vid => vid.id)

      // make the video list sortable
      Sortable.create(this.$refs.sortableList, {
        draggable: 'li',
        animation: 80,
        onUpdate: e => {
          this.existingFiles = arrayMove(this.existingFiles, e.oldIndex, e.newIndex)
          this.saveOrder()
        },
      })

      this.uppy.use(DragDrop, { target: this.$refs.dragdrop })
        .use(Tus, {
          endpoint: `${$themeConfig.app.apiUrl}/playlists/${router.currentRoute.params.id}/videos?token=${token}&expiry=${expiry}`,
          chunkSize: 150 * 1024 * 1024,
          withCredentials: false,
        })
        .use(ProgressBar, { target: this.$refs.progressbar, hideAfterFinish: true })
        .on('files-added', this.fileAdded)
        .on('upload-success', file => this.fileStatusChange(file, 'complete'))
        .on('upload-error', (file, error) => this.fileStatusChange(file, 'error', error))
        .on('complete', () => { this.uploading = false; this.$emit('videoAdded'); this.reloading = true })
    });
  },
  methods: {
    startUpload() {
      this.uppy.upload()
      this.uploading = true
    },
    fileAdded(files) {
      this.uppyFileList = [
        ...this.uppyFileList,
        ...files.reduce((acc, val) => [...acc, { id: val.id, name: val.name, status: 'waiting' }], []),
      ]
    },
    fileStatusChange(file, status) {
      const { id } = file
      this.uppyFileList = [
        ...this.uppyFileList.filter(val => val.id !== id),
        {
          ...file,
          status,
        },
      ]
    },
    saveOrder() {
      store.dispatch(
        'app/updateVideoOrder',
        {
          uuid: this.contentItemData.id,
          videoOrder: this.existingFiles.reduce((acc, val, index) => {
            acc[index] = val
            return acc
          }, []),
        },
      )
    },
    deleteVideo(videoId) {
      store.dispatch(
        'app/deleteVideo',
        {
          uuid: this.contentItemData.id,
          videoId,
        },
      )
        .then(() => {
          this.contentItemData.content.videos = [...this.contentItemData.content.videos.filter(val => val.id !== videoId)]
          this.existingFiles = [...this.existingFiles.filter(val => val !== videoId)]
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .greenIcon { stroke: #019135; fill: rgba(1, 145, 53, 0.12) }
  .redIcon { stroke: #ef4323; fill: rgba(239, 67, 35, 0.32) }

  .videoList {
    list-style-type: none;
    padding-left: 0;
    margin-left: -0.5em;
  }

  .videoList li {
    cursor: pointer;
    display: inline-block;
    margin: 0.5em 0.5em;
    position: relative;
  }

  .videoList li .videoDelete {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }

  .videoList li img {
    max-width: 300px;
  }
</style>

<style>
  .dark-layout [dir] .uppy-DragDrop-container {
    background: rgba(255, 255, 255, 0.1);
  }

  .dark-layout [dir] .uppy-DragDrop-label {
    color: white;
  }

  .dark-layout .uppy-DragDrop-browse {
    color: #16a3dd;
  }
</style>
